import {User} from '../types';
import {getUserHash} from './index';

export const TrackRegistration = (formData: any) => {
  // @ts-ignore
  const fbq = window.fbq || global.fbq;
  // @ts-ignore
  if (fbq && typeof fbq === 'function') {
    // @ts-ignore
    fbq('track', 'CompleteRegistration');
  }

  // @ts-ignore
  const ga = window.ga || global.ga;
  // @ts-ignore
  if (ga && typeof ga === 'function') {
    // @ts-ignore
    ga('set', 'uid', formData.email);
    // @ts-ignore
    ga('send', {
      hitType: 'event',
      eventCategory: 'email-signup',
      eventAction: 'success',
      eventLabel: formData.email,
    });
  }
};

export const TrackToolScan = (name: string, input: string) => {
  // @ts-ignore
  if (!global.window) return;
  // @ts-ignore
  const fbq = window.fbq || global.fbq;

  // @ts-ignore
  if (fbq && typeof fbq === 'function') {
    // @ts-ignore
    fbq('trackCustom', 'ToolScan', {tool: name, input});
    // @ts-ignore
    fbq('trackCustom', `${name}ToolScan`, {input});
  }
};

export const TrackExtensionLinkClick = (browser: string) => {
  // @ts-ignore
  const fbq = window.fbq || global.fbq;

  // @ts-ignore
  if (fbq && typeof fbq === 'function') {
    // @ts-ignore
    fbq('trackCustom', 'ExtensionLinkClick', {browser});
    // @ts-ignore
    fbq('trackCustom', `${browser}ExtensionLinkClick`, {browser});
  }
};

export const TrackToolSubscribe = (input: string, name: string) => {
  // @ts-ignore
  const fbq = window.fbq || global.fbq;

  // @ts-ignore
  if (fbq && typeof fbq === 'function') {
    // @ts-ignore
    fbq('trackCustom', 'ToolSubscribe', {tool: name, input});
    // @ts-ignore
    fbq('trackCustom', `${name}ToolSubscribe`, {input});
  }
};

export const setFreshChatUserData = (user?: User) => {
  const setUserInterval = setInterval(() => {
    const initData = {
      app_id: 'kxq8ogrw',
    };
    if (user && user.firstName && user.email) {
      const userInfo = {
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
        user_hash: getUserHash(),
      };
      //@ts-ignore
      global.window &&
      //@ts-ignore
        window.Intercom &&
        //@ts-ignore
        window.Intercom('boot', {
          ...initData,
          ...userInfo,
        });
    }
    clearInterval(setUserInterval);
  }, 2000);
};

export const openCalendly = () => {
  //@ts-ignore
  global.window &&
    //@ts-ignore
    window.Calendly &&
    //@ts-ignore
    window.Calendly.initPopupWidget({url: 'https://calendly.com/hexact/hexowatch-demo?hide_gdpr_banner=1'});
  return false;
};
