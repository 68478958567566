import React, {useState, useEffect} from 'react';
import {useMutation} from '@apollo/react-hooks';
import {DEMO_REQUEST_SUBMIT} from '../../graphql/mutations';
import Message from '../messages';
import Button from '../button';
import Select from '../select';
import {getUserExists, setShowModal, getShowModal} from '../../helpers';
import {countryOptions} from '../../constans/countryList';
import {isValidEmail} from '../../helpers/constants';
import {checkedCircleIcon} from '../../images/svgIcons';
import quote from '../../images/svgIcons/quote.svg';
import quoteAuthor from '../../images/svgIcons/quoteAuthor.svg';
import '../../styles/auth.scss';
import './styles.scss';

const numberOfEmployeesOptions = [
  {label: '1-10', value: '1-10'},
  {label: '11-50', value: '11-50'},
  {label: '51-200', value: '51-200'},
  {label: '201-500', value: '201-500'},
  {label: '501-1000', value: '501-1000'},
  {label: '1000+', value: '1000+'},
];

const RequestDemo = ({setHandleCloseDemoModal, setDemoSuccess, setTimeoutId}: any): JSX.Element => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [country, setCountry] = useState({label: 'Select', value: ''});
  const [numberOfEmployees, setNumberOfEmployees] = useState({label: 'Select', value: ''});
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const [sendRequestDemo, {loading}] = useMutation(DEMO_REQUEST_SUBMIT);

  global.document &&
    global.document.addEventListener('mousemove', e => {
      if (e.clientY < 20 && getShowModal() !== 'false' && getUserExists() !== 'true') {
        setShowModal('true');
        //setShow("true");
      }
    });

  const handleSubmit = async () => {
    setErrorMessage('');
    if (
      !name.trim() ||
      !lastName.trim() ||
      !businessEmail.trim() ||
      !numberOfEmployees?.value ||
      !company.trim() ||
      !role.trim() ||
      !message.trim()
    ) {
      setErrorMessage('Please fill out all the required fields');
      return;
    } else if (!isValidEmail(businessEmail.trim())) {
      setErrorMessage('Please enter valid email address');
      return;
    } else if (!countryOptions.find(item => item.label === country.label)) {
      setErrorMessage('Please enter a valid country');
      return;
    }

    const {data} = await sendRequestDemo({
      variables: {
        settings: {
          name: name,
          lastName: lastName,
          businessEmail: businessEmail,
          country: country.label,
          jobTitle: role,
          message: message,
          company: company,
          numberOfEmployees: numberOfEmployees.value,
          productType: 'hexowatch',
        },
      },
    });
    if (data && data.DemoRequestOps && data.DemoRequestOps.sendEmail && data.DemoRequestOps.sendEmail.error) {
      setErrorMessage('Something went wrong.');
    } else {
      if (window.location.href.includes('hexowatch.com')) {
        //@ts-ignore
        window.dataLayer.push({
          event: 'demo-request-hexowatch',
          demoRequestId: `Demo_Request_${new Date().toISOString()}`,
        });
      }
      setSuccess(true);
      setDemoSuccess(true);
    }
  };

  const handleChangeName = (e: any) => {
    setName(e.target.value);
    setErrorMessage('');
  };
  const handleChangeLastName = (e: any) => {
    setLastName(e.target.value);
    setErrorMessage('');
  };
  const handleChangeEmail = (e: any) => {
    setBusinessEmail(e.target.value);
    setErrorMessage('');
  };
  const handleChangeRole = (e: any) => {
    setRole(e.target.value);
    setErrorMessage('');
  };
  const handleChangeCompany = (e: any) => {
    setCompany(e.target.value);
    setErrorMessage('');
  };
  const handleChangeMessage = (e: any) => {
    setMessage(e.target.value);
    setErrorMessage('');
  };

  useEffect(() => {
    if (success) {
      const id = setTimeout(() => {
        window.location.href = 'https://calendly.com/hexact/hexowatch-demo';
      }, 60000);
      setTimeoutId(id);
    }
  }, [success]);

  return (
    <div className={`demo-block ${success ? 'px-4' : ''}`}>
      {!success ? (
        <>
          <div className="row">
            <div className="d-flex col-12 col-lg-6 text-center testimonial-section">
              <div className="m-auto">
                <img src={quote} alt="icon" className="mb-4 mt-4 mt-lg-0" />
                <div className="px-2 px-md-5">
                  "Hexowatch is one of those tools where once you begin using it, you can’t imagine life without it. In
                  particular, it saves me a lot of time and increases accuracy when it comes to visually comparing
                  WordPress web pages after applying updates."
                </div>
                <img src={quoteAuthor} alt="author" className="my-4" />
              </div>
            </div>
            <div className="col-12 col-lg-6 pr-lg-0">
              <h4 className="title">Tell us a little more about your business so we can serve you better.</h4>
              <div className="forms">
                <div className="wrapper">
                  <div className="form-item">
                    <label htmlFor="name">
                      Name <span>*</span>
                    </label>
                    <input
                      required
                      placeholder="John"
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleChangeName}
                      value={name}
                      maxLength={255}
                      className={errorMessage && !name.trim() ? 'danger' : ''}
                    />
                  </div>
                  <div className="form-item">
                    <label htmlFor="lastname">
                      Last name <span>*</span>
                    </label>
                    <input
                      required
                      placeholder="Smith"
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={handleChangeLastName}
                      value={lastName}
                      maxLength={255}
                      className={errorMessage && !lastName.trim() ? 'danger' : ''}
                    />
                  </div>
                </div>
                <div className="wrapper">
                  <div className="form-item">
                    <label htmlFor="workEmail">
                      Business email <span>*</span>
                    </label>
                    <input
                      className={
                        errorMessage === 'Please enter valid email address' || (errorMessage && !businessEmail.trim())
                          ? 'danger'
                          : ''
                      }
                      required
                      placeholder="example@company.com"
                      type="email"
                      name="workEmail"
                      id="workEmail"
                      onChange={handleChangeEmail}
                      value={businessEmail}
                      maxLength={320}
                    />
                  </div>
                  <div className="form-item width_48">
                    <label htmlFor="jobTitle">
                      Job title <span>*</span>
                    </label>
                    <input
                      className={errorMessage && !role.trim() ? 'danger' : ''}
                      required
                      placeholder="Sales Manager"
                      type="text"
                      name="role"
                      id="role"
                      onChange={handleChangeRole}
                      value={role}
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className="d-flex wrapper-fields justify-content-between align-items-center">
                  <div className="form-item width_48">
                    <label>
                      Country <span>*</span>
                    </label>
                    <Select
                      className={`${errorMessage && !country.value ? 'brd-err' : ''} `}
                      options={countryOptions}
                      value={country}
                      onChange={setCountry}
                      searchable
                      placeholder="Select"
                    />
                  </div>
                  <div className="form-item width_48">
                    <label htmlFor="jobTitle">
                      Company <span>*</span>
                    </label>
                    <input
                      className={errorMessage && !company.trim() ? 'danger' : ''}
                      required
                      placeholder="Hexact Inc,"
                      type="text"
                      name="company"
                      id="company"
                      onChange={handleChangeCompany}
                      value={company}
                      maxLength={1000}
                    />
                  </div>
                </div>
                <div className="d-flex wrapper-fields justify-content-between align-items-center">
                  <div className="form-item w-100">
                    <label>
                      Number of employees <span>*</span>
                    </label>
                    <Select
                      className={`${errorMessage && !numberOfEmployees.value ? 'brd-err' : ''} `}
                      options={numberOfEmployeesOptions}
                      value={numberOfEmployees}
                      onChange={setNumberOfEmployees}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-start flex-column w-100">
                  <label className="textarea-label" htmlFor="message">
                    Message <span>*</span>
                  </label>
                  <textarea
                    value={message}
                    maxLength={2000}
                    onChange={handleChangeMessage}
                    name="message"
                    id="message"
                    placeholder="Tell us about the problem you would like to solve using Hexowatch so we can tailor your demo experience."
                    cols={30}
                    rows={10}
                    required
                    className={errorMessage && !message.trim() ? 'danger' : ''}
                  />
                </div>

                {errorMessage && (
                  <div className="mt-2">
                    <Message type="error" text={errorMessage || 'Please fill out all the required fields'} />
                  </div>
                )}
              </div>

              <div className="submit-block">
                <Button loading={loading} disabled={loading} primary className="mt-3 mb-2" onClick={handleSubmit}>
                  Request a demo
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column text-center align-items-center justify-content-center">
          <div className="check-icon">{checkedCircleIcon}</div>
          <div className="mt-3">Thank you for your interest in Hexowatch.</div>
          <div className="mt-3">
            By clicking the <strong>"Choose a time"</strong> button below you will be redirected to the booking page.
            Please feel free to book a time at your convenience.
          </div>
          <div className="mt-3">Looking forward to our call.</div>
          <Button
            primary
            className="my-3"
            onClick={() => (window.location.href = 'https://calendly.com/hexact/hexowatch-demo')}
          >
            Choose a time
          </Button>
          <small>You will be redirected to the booking page in a minute</small>
        </div>
      )}
    </div>
  );
};

export default RequestDemo;
