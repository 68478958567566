import React, {useState, useEffect} from 'react';
import './styles.scss';

const Counter: React.FC<{
  date: string;
  className?: string;
  title?: string;
  titleColor?: string;
  light?: boolean;
  titleLarge?: boolean;
  red?: boolean;
}> = ({date, className, title, titleColor, light, titleLarge, red}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(date ? date : 'Jan 28 2022 08:00:00 GMT') - +new Date(new Date().toUTCString());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  }, []);

  return (
    <>
      {//@ts-ignore
      timeLeft && (timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds) ? (
        <div className={`${className ? className : ''} ${light ? 'light' : ''} countdown_container d-block ${red ? 'cd-red' : ''}`}>
          {title ? (
            <div
              style={{
                color: titleColor ? titleColor : '#050038',
                fontSize: titleLarge ? '20px' : '13px',
                textAlign: 'center',
                lineHeight: titleLarge ? '32px' : '19px',
                fontWeight: 500,
              }}
            >
              {title}
            </div>
          ) : (
            ''
          )}
          <div className="d-flex justify-content-center">
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.days
                }
              </div>
              <div className="label_container">Days</div>
            </div>
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.hours
                }
              </div>
              <div className="label_container">Hours</div>
            </div>
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.minutes
                }
              </div>
              <div className="label_container">Minutes</div>
            </div>
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.seconds
                }
              </div>
              <div className="label_container">Seconds</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Counter;
