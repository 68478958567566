import React, { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faTimes,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { MAGNET_SUBSCRIBE } from "../../graphql/mutations";
import { setShowModal } from "../../helpers";
import other from "../../images/lead-magnet/automation-playbook-3d.png";
import eCommerce from "../../images/lead-magnet/ecommerce-playbook-3d.png";
import agency from "../../images/lead-magnet/3-Agency-cover.png";
import "./styles.scss";

const businesses = [
  {
    name: "eCommerce",
    img: eCommerce
  },
  {
    name: "Agency",
    img: agency
  },
  {
    name: "Other",
    img: other
  }
];

const SubscriptionModal: FC<{ isModalOpen: boolean }> = ({ isModalOpen }) => {
  const [subscribe, { loading }] = useMutation(MAGNET_SUBSCRIBE);

  const [field, setField] = useState({
    name: "",
    img: ""
  });
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
    isModalOpen && document.body.classList.add("overflow-hidden");
  }, [isModalOpen]);

  const handleSubscribe = async () => {
    setEmailError("");
    if (!termsAccepted) {
      setCheckboxError(
        "Please accept the Terms and the Privacy Policy to continue"
      );
    } else {
      const { data } = await subscribe({
        variables: {
          email: email,
          type: "MAGNET",
          comment: field.name
        }
      });
      const { error, message } =
        data &&
        data.WatchSubscribeOps &&
        data.WatchSubscribeOps.launchSubscribe;
      if (error) {
        setEmailError(message);
      } else {
        setEmail("");
        setSuccess(true);
        setShowModal("false");
      }
    }
  };

  const handleModalClose = () => {
    setShowModal("false");
    setIsOpen(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleModalBack = (): void => {
    setField({
      name: "",
      img: ""
    });
  };

  return (
    <div className={classnames("modal-magnet", { "d-none": !isOpen })}>
      {loading && (
        <FontAwesomeIcon icon={faCircleNotch} spin className="spin" size="3x" />
      )}
      <div
        className={classnames("modal-magnet-content", {
          "modal-blur": loading
        })}
      >
        <div
          className={`d-flex ${
            field.name ? "justify-content-between" : "justify-content-end"
          }
          `}
        >
          {field.name && (
            <div className="modal-close">
              <FontAwesomeIcon icon={faArrowLeft} onClick={handleModalBack} />
            </div>
          )}
          <div className="modal-close">
            <FontAwesomeIcon icon={faTimes} onClick={handleModalClose} />
          </div>
        </div>

        <div className="modal-body">
          {field.name && !success && (
            <p>
              Sign up to our{" "}
              {field.name === "Other" ? "Monitoring" : field.name}{" "}
              {field.name === "Agency" ? "use cases " : "playbook "}
              insights to receive your free strategy playbook and our latest{" "}
              <span>
                {field.name === "Other"
                  ? "monitoring"
                  : field.name === "Agency"
                  ? "agency"
                  : "eCommerce"}
              </span>{" "}
              case studies.
            </p>
          )}
          {success && (
            <>
              <p className="success-modal-text">Thank you</p>
              <p className="success-modal-text">
                Please check your inbox to confirm your email and download your
                playbook.
              </p>
            </>
          )}
          <img
            src={field.img ? field.img : other}
            alt={
              field.name
                ? `${
                    field.name === "Other" ? "Monitoring" : field.name
                  } use cases`
                : "Automation use cases"
            }
          />
          {!field.name && !success && (
            <p>
              Download our free strategy playbook packed with strategies,
              mindmaps and case studies tailored to your business.
            </p>
          )}
          {!field.name &&
            !success &&
            businesses.map(item => (
              <button
                onClick={() => setField(item)}
                type="button"
                className="btn"
                key={item.name}
              >
                {item.name}
              </button>
            ))}
          {field.name && !success && (
            <>
              <input
                value={email}
                type="email"
                name="email"
                onChange={e => {
                  setEmailError("");
                  setEmail(e.target.value);
                }}
                placeholder="Enter your email here"
                className={classnames("subscribe-email", {
                  "error-border": emailError
                })}
              />
              <label>
                <div
                  className={classnames("subscribe-checkbox", {
                    "error-border": checkboxError
                  })}
                >
                  {termsAccepted && <div className="selected"></div>}
                  <input
                    type="checkbox"
                    required
                    checked={termsAccepted}
                    onChange={() => {
                      setCheckboxError("");
                      setTermsAccepted(!termsAccepted);
                    }}
                  />
                </div>
                I accept{" "}
                <a href="https://hexowatch.com/terms-of-use" target="_blank">
                  the Terms
                </a>{" "}
                and{" "}
                <a href="https://hexowatch.com/privacy-policy" target="_blank">
                  the Privacy Policy
                </a>
              </label>
              <button
                onClick={handleSubscribe}
                type="button"
                className="btn subscribe-btn"
              >
                Access the{" "}
                <span>
                  {field.name === "Other"
                    ? "monitoring playbook"
                    : field.name === "Agency"
                    ? "agency use cases"
                    : "eCommerce playbook"}
                </span>{" "}
              </button>
              {(emailError || checkboxError) && (
                <p className="error-message">{emailError || checkboxError}</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
